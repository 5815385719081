<template>
  <!-- 社区团购 -->
  <div class="groupShop">
    社区团购
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
